<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <v-container>
      <v-row>
        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="t_start_position"
            :rules="[v => !!v || 'Måste fyllas i']"
            type="number"
            label="Lagets startnummer"
            required
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="t_corr_tricky_question"
            type="number"
            label="Korrekt kluring (antal)"
          ></v-text-field>
        </v-col>

        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="t_corr_haftig"
            type="number"
            label="Korrekt HÄFTig (antal)"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <!-- NOT USED IN RALLY 22 -->
        <!-- <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="t_corr_splek"
            :rules="[v => !!v || 'Måste fyllas i']"
            type="number"
            label="Korrekt SpleK (antal)"
            required
          ></v-text-field>
        </v-col> -->
        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="t_missions_time_reduction"
            type="number"
            label="Tidsavdrag uppdrag (minuter)"
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="t_extra_time_reduction"
            type="number"
            label="Övrig tidsavdrag (minuter)"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="t_no_lunch_box"
            type="number"
            label="Deltagare utan lunchlådor (antal)"
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="t_extra_time_addition"
            type="number"
            label="Övrig tidspålägg (min)"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-btn
        :disabled="!valid"
        color="success"
        class="mr-4"
        @click="submit"
      >
        Uppdatera
      </v-btn>
    </v-container>
  </v-form>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import {notify} from '../../utils/notification'

export default {
  name: 'Correction',
  data: () => ({
    valid: false,
    t_start_position: null,
    t_corr_tricky_question: 0,
    t_corr_haftig: 0,
    t_corr_splek: 0,
    t_missions_time_reduction: 0,
    t_extra_time_reduction: 0,
    t_no_lunch_box: 0,
    t_extra_time_addition: 0
  }),
  methods: {
    ...mapActions(['correctTeam', 'clearNotifications']),
    async submit() {
      if (this.$refs.form.validate()) {
        await this.correctTeam({
          t_start_position: this.t_start_position,
          // These values could become null on form.reset(), therefore make sure that null is never sent
          t_corr_tricky_question: this.t_corr_tricky_question ? this.t_corr_tricky_question : 0,
          t_corr_haftig: this.t_corr_haftig ? this.t_corr_haftig : 0,
          // t_corr_splek: this.t_corr_splek,
          t_missions_time_reduction: this.t_missions_time_reduction ? this.t_missions_time_reduction : 0,
          t_extra_time_reduction: this.t_extra_time_reduction ? this.t_extra_time_reduction : 0,
          t_no_lunch_box: this.t_no_lunch_box ? this.t_no_lunch_box : 0,
          t_extra_time_addition: this.t_extra_time_addition ? this.t_extra_time_addition : 0
        })

        // Notification
        const success = notify(this)
        if (success) {
          this.$refs.form.reset()
        }
      }
    }
  },
  computed: mapGetters(["notification"])
}
</script>

<style>

</style>